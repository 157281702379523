import React, { useState, useEffect, useContext, useRef } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import MovingText from 'react-moving-text';

import { Box, Typography, Container, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

// layout
import StartLayout from '/layouts/StartLayout/start';

// store
import { GlobalContext } from '/context';
// utils
import useWindowSize from '/utils/useWindowSize';
import { getCovers, addCover, deleteCover } from '/context/actions/cover';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        padding: 26,
        margin: 0,
    },

    startContainer: (props) => {
        return {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundSize: 'cover',
            flexDirection: props.windowsize > 600 ? 'row' : 'column',
        };
    },
    cardBox: (props) => {
        return {
            width: 234,
            height: 234,
            borderColor: 'rgba(255, 255, 255, 0.26)',
            borderWidth: 2,
            borderRadius: 30,
            margin: props.windowsize > 600 ? 30 : 10,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            backdropFilter: 'blur(2px)',
            background: 'rgba(255, 255, 255, 0.48)',
            '&:hover': {
                background: 'rgba(255, 255, 255, 0.02)',
                borderColor: 'rgba(255, 255, 255, 0.09)',
                cursor: 'pointer',
                backdropFilter: 'blur(4px)',

                // transform: 'scale(1.1)',
            },
        };
    },
    cardBlur: {
        width: 234,
        height: 234,
        filter: 'blur(1px)',
        background: 'rgba(255, 255, 255, 0.58)',
        backdropFilter: 'blur(4px)',
    },

    logo: {
        width: 106,
        height: 106,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '10px',
    },
    text: {
        color: '#004520',
        fontSize: 20,
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: '24.2px',
        width: '150px',
    },
}));

import img from '/public/start/khutag.png';
import one from '/public/start/one.png';
import two from '/public/start/two1.png';
import three from '/public/start/three.png';
const AnimationsForChaining = ['fadeOutToBottom', 'zoomIn'];

const AnimationChain = () => {
    const [animationIndex, setAnimationIndex] = useState(0);
    const timerRef = useRef(null);

    const [animationType, setAnimationType] = useState(AnimationsForChaining[0]);

    const handleChainAnimation = () => {
        const nextIndex = (animationIndex + 1) % AnimationsForChaining.length;
        setAnimationIndex(nextIndex);
        setAnimationType(AnimationsForChaining[nextIndex]);
        console.log('ended');
    };

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setAnimationType(null); // Clear the animation
            console.log('Animation chain ended');
        }, 10000);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    return (
        <MovingText
            onAnimationEnd={handleChainAnimation}
            type={animationType}
            duration="1000ms"
            timing="linear"
            fillMode="backwards"
            iteration={1}
            style={{
                textAlign: 'center',
                color: '#222652',
                fontSize: '40px',
                fontWeight: 700,
                lineHeight: '59.77px',
            }}
        >
            Булган аймгийн Улсын тэргүүний <br /> Хутаг-Өндөр сум
        </MovingText>
    );
};
export default function StartPage() {
    const { width } = useWindowSize();
    const sliderHeight = width < 600 ? 200 : 400;
    const classes = useStyles({ windowsize: width });
    const { state } = useContext(GlobalContext);
    const [cover, setCover] = useState(null);
    const [page, setPage] = useState(1);
    const [shape, setShape] = useState([]);
    const routers = ['home', 'holiday', 'museum'];
    const timerRef = useRef(null);

    const [animationType, setAnimationType] = useState('popOut');

    // const router = useRouter();
    // const { asPath, pathname, query } = router;
    // console.log('Current path:', asPath); // Full path with query string
    // console.log('Pathnamedsds:', pathname); // Path without query string
    // console.log('Query:', query);
    useEffect(() => {
        getCovers('?page=' + page).then((result) => {
            const newShape = [];
            if (result.list) {
                let newCover = null;
                let sorted = result.list.sort((a, b) => a.pos - b.pos);
                sorted.forEach((item) => {
                    if (item.is_cover) {
                        newCover = item;
                    } else {
                        newShape.push(item);
                    }
                });
                setCover(newCover);
            }

            setShape(newShape);
        });
    }, []);

    return (
        <div className="h-screen">
            <Box
                fullwidth="true"
                maxHeight="100vh"
                style={{
                    backgroundImage: `url('${cover ? cover.image : img.src}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100vh',
                }}
            >
                <div
                    style={{
                        marginTop: 50,
                        position: 'absolute',
                        width: '100%',
                    }}
                >
                    {/* <AnimationChain /> */}
                    <MovingText
                        type={animationType}
                        duration="1400ms"
                        delay="1s"
                        direction="alternate"
                        timing="ease-in"
                        iteration="2"
                        fillMode="forwards"
                        style={{
                            textAlign: 'center',
                            color: '#222652',
                            fontSize: '40px',
                            fontWeight: 700,
                            lineHeight: '59.77px',
                        }}
                    >
                        Булган аймгийн Улсын тэргүүний <br /> Хутаг-Өндөр сум
                    </MovingText>
                </div>
                <div className={classes.startContainer}>
                    {shape.length > 0 ? (
                        shape.map((item, i) => (
                            <Link
                                key={i}
                                href={`/${item.path}`}
                                rel="noreferrer"
                                className={classes.cardBox}
                            >
                                <Box className={classes.logo}>
                                    <Image
                                        loader={() => item.image}
                                        src={item.image}
                                        width={106}
                                        height={106}
                                        quality={100}
                                        alt=""
                                        unoptimized
                                        fetchpriority="high"
                                        priority
                                    />
                                </Box>
                                <Typography className={classes.text}>{item.title}</Typography>
                            </Link>
                        ))
                    ) : (
                        <>
                            <Link className={classes.cardBox} href={'/home'} rel="noreferrer">
                                <Box className={classes.logo}>
                                    <Image
                                        loader={() => one}
                                        src={one}
                                        width={106}
                                        height={106}
                                        quality={100}
                                        alt=""
                                        unoptimized
                                        fetchpriority="high"
                                        priority
                                    />
                                </Box>
                                <Typography className={classes.text}>
                                    Сумын цахим хуудсанд зочлох
                                </Typography>
                            </Link>
                            <Link className={classes.cardBox} href={'/holiday'} rel="noreferrer">
                                <Box className={classes.logo}>
                                    <Image
                                        loader={() => two}
                                        src={two}
                                        width={106}
                                        height={106}
                                        quality={100}
                                        objectFit="contain"
                                        alt=""
                                        fetchpriority="high"
                                        priority
                                        unoptimized
                                    />
                                </Box>
                                <Typography className={classes.text}>
                                    Түүхт 100 жилийн ой
                                </Typography>
                            </Link>
                            {/* <Link className={classes.cardBox} href={'/museum'} rel="noreferrer">
                            <Box className={classes.logo}>
                                <Image
                                    loader={() => three}
                                    src={three}
                                    width={106}
                                    height={106}
                                    quality={100}
                                    objectFit="contain"
                                    alt=""
                                    unoptimized
                                    fetchpriority="high"
                                    priority
                                />
                            </Box>
                            <Typography className={classes.text}>Цахим музей</Typography>
                        </Link> */}
                        </>
                    )}
                </div>
            </Box>
        </div>
    );
}

// StartPage.defaultProps = {
//     page: 'start',
// };

StartPage.Layout = StartLayout;
