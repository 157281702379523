import { useEffect, useState } from 'react';

export default function useWindowSize(props) {
    // const isSSR = process.browser ? true : false;

    const [windowsize, setWindowSize] = useState({
        width: 1200,
        height: 800,
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            window.addEventListener('resize', handleResize);

            handleResize();

            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    return windowsize;
}
