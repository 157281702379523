const fetch = require('utils/fetchRequest');

export const getCovers = async (query) => {
    try {
        const response = await fetch.get('covers' + query);
        return response.data;
    } catch (err) {
        return { error: 'Сервертэй холбогдож чадсангүй' };
    }
};

export const addCover = async (datas) => {
    const result = await fetch.post('covers', datas);
    return result;
};

export const deleteCover = async (id) => {
    const result = await fetch.destroy('covers/' + id);
    return result;
};
