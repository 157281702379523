import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

// store
import { GlobalContext } from '/context';

// actions
import { getMenus } from '/context/actions/menu';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        background: 'white',
    },
}));

export default function StartLayout({ children }) {
    const classes = useStyles();
    const { state, dispatch } = useContext(GlobalContext);

    return (
        <div>
            <main>
                <Box className={classes.root}>{children}</Box>
            </main>
        </div>
    );
}
